export enum STOP_CODES {
	REQUEST = 'REQUEST',
	REGISTRATION = 'REGISTRATION'
}

export enum STOP_CASES {
	DEFAULT = 'DEFAULT',
	MAX_ATTEMPTS = 'MAX_ATTEMPTS',
	NO_CONTACTS = 'NO_CONTACTS',
	NOT_FOUND = 'NOT_FOUND',
	INCORRECT_USER = 'INCORRECT_USER',
	FV_NOT_BUR = 'FV_NOT_BUR',
	EA_AS_BUR = 'EA_AS_BUR',
	EA_AS_FV = 'EA_AS_FV',
	EA_AS_REGISTERED = 'EA_AS_REGISTERED',
	EA_AS_NOT_REGISTRABLE = 'EA_AS_NOT_REGISTRABLE'
}
